<template>
<div class="ReportDownload">

  <a-dropdown-button
    @click="handleDownloadClick"
    class="ReportDownload__button"
    type="green"
    v-if="requestInit.agreement?.consent_form_read_at"
  >
    Download report
    <template #overlay>
      <a-menu
        @click="handleDownloadMenuClick"
      >
        <a-menu-item key="report">
          Download Report (PDF)
        </a-menu-item>
        <a-menu-item key="report_word">
          Download Report (Word)
        </a-menu-item>
        <a-menu-item key="consent">
          Download Consent Form
        </a-menu-item>
      </a-menu>
    </template>
    <template #icon>
      <CaretDownOutlined />
    </template>
  </a-dropdown-button>
  <a-dropdown-button
    class="ReportDownload__button"
    type="green"
    @click="handleDownloadClick"
    v-else
  >
    Download report
    <template #overlay>
      <a-menu
          @click="handleDownloadMenuClick"
      >
        <a-menu-item key="report">
          Download Report (PDF)
        </a-menu-item>
        <a-menu-item key="report_word">
          Download Report (Word)
        </a-menu-item>
      </a-menu>
    </template>
    <template #icon>
      <CaretDownOutlined />
    </template>
  </a-dropdown-button>

  <a-button
      class="ReportDownload__button-reset"
      type="primary"
      @click="handleEditReportClick"
  >
    Edit report
  </a-button>
  
  <a-button
    class="ReportDownload__button-reset"
    @click="$router.push({ name: 'cms.requests' })"
  >
    Back to requests
  </a-button>
  
</div>
</template>

<script>
import { saveAs } from "file-saver"
import { mapGetters } from "vuex"
import { CmsReportService } from "@/services/api_v1/cms"
import { CaretDownOutlined } from '@ant-design/icons-vue'

export default {
  name: "ReportDownload",
  components: {
    CaretDownOutlined,
  },
  computed: {
    ...mapGetters({
      getPdf: 'CmsRequestStore/getPdf',
      requestInit: 'CmsRequestStore/getInit',
    }),
    barcode() {
      return this.$route.params.barcode
    },
    pdf() {
      return this.getPdf(this.barcode) || ''
    },
    word() {

    },
  },
  methods: {
    handleDownloadClick() {
      saveAs(this.pdf, `${this.barcode}.pdf`)
    },
    handleEditReportClick() {
      this.$router.push({ name: 'cms.symptoms', params: { barcode: this.barcode } })
    },
    async handleDownloadMenuClick(event) {
      if (event.key === "report") {
        this.handleDownloadClick()
      }
      if (event.key === "consent") {
        const link = document.createElement('a')
        link.download = `${this.requestInit.patient.name_underscore}-${this.barcode}_Consent.pdf`
        await CmsReportService.downloadConsentForm(this.barcode)
          .then((response) => {
            if (response.status === 200) {
              link.href = response.data
              link.click()
              link.remove()
            }
          })
      }
      if (event.key === "report_word") {
        const link = document.createElement('a')
        link.download = `${this.barcode}.docx`
        await CmsReportService.downloadWord(this.barcode)
            .then((response) => {
              if (response.status === 200) {
                link.href = response.data
                link.click()
                link.remove()
              }
            })
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.ReportDownload {
  
  &__button {
    width: 100%;
    
    &:deep(.ant-btn:first-child) {
      width: 100%;
    }
  }
  
  &__button-reset {
    width: 100%;
    margin-top: 24px;
  }
  
}
</style>