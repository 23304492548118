<template>
<div class="ReportForm">
  
  <a-form-item
    class="ReportForm__field"
    name="approve"
    label="Enter ‘ACCEPT’ to sign the report"
  >
    <a-input
      v-model:value="approve"
    />
  </a-form-item>
  
  <a-button
    type="green"
    class="ReportForm__button"
    :disabled="approve.toUpperCase() !== 'ACCEPT'"
    html-type="submit"
    @click="onSign"
  >
    Sign
  </a-button>
  
</div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex"
import { toast } from "@/helpers/error.helper"
import moment from "moment";

export default {
  name: "ReportForm",
  data() {
    return {
      approve: '',
    }
  },
  computed: {
    ...mapGetters({
      requestIsInit: 'CmsRequestStore/getIsInit',
      requestInit: 'CmsRequestStore/getInit',
    }),
    barcode() {
      return this.$route.params.barcode
    },
  },
  methods: {
    ...mapMutations({
      setRequestInitField: 'CmsRequestStore/setItemInitField',
      setRequestField: 'CmsRequestStore/setItemField',
    }),
    ...mapActions({
      signRequest: 'CmsRequestStore/sign',
    }),
    async onSign() {
      await this.signRequest(this.barcode)
        .then((response) => {
          if (response.status === 200) {
            toast({ type: 'success', msg: 'Sign successful' })
            this.setRequestInitField({ field: 'status', value: 'report_completed' })
            this.setRequestInitField({ field: 'report_completed_at', value: moment().format("DD-MM-YYYY") })
            this.$emit('loadPdf')
            
            if (this.requestIsInit) {
              this.setRequestField({ id: this.requestInit.id, field: 'status', value: 'report_completed' })
            }
          }
        })
    },
  }
}
</script>

<style lang="scss" scoped>
.ReportForm {
  
  &__button {
    width: 100%;
  }

  &__field {
    margin-bottom: 15px;
    
    &:deep(.ant-form-item-label) > label {
      font-weight: 400;
      font-size: 14px;
      line-height: 16px;
      color: $secondary45;
    }
  }
  
}
</style>