<template>
<AuthLayout
  :breadcrumbs="breadcrumbs"
  :sidebar="true"
>
<template #title>
  
  <StepsLayout
    active="report"
  />
  
</template>
<template #content>
  <div
    class="ReportPage"
    ref="content"
    :style="`height: calc(100vh - ${sum([
      48, // HeaderLayout
      104, // SubHeaderLayout
      24, // AuthLayout__wrap
    ])}px)`"
  >
    
    <template v-if="pdf">
      <vue-pdf-embed
        :source="pdf"
      />
    </template>
    <template v-else>
      <a-skeleton-image
        class="ReportPage__pdf-skeleton"
      />
    </template>
  
  </div>
</template>
<template #sidebar>
  
  <template v-if="requestInit.status === `conclusion_added`">
    <ReportForm
      ref="ReportForm"
      @loadPdf="loadPdf"
    />
  </template>
  <template v-else-if="requestInit.status === `report_completed`">
    <ReportDownload />
  </template>
  
</template>
</AuthLayout>
</template>

<script>
import VuePdfEmbed from 'vue-pdf-embed'
import AuthLayout from "@/components/cms/auth.layout/AuthLayout"
import StepsLayout from "@/components/cms/auth.layout/StepsLayout"
import ReportForm from "@/components/cms/report/ReportForm"
import ReportDownload from "@/components/cms/report/ReportDownload"
import { CmsReportService } from "@/services/api_v1/cms"
import { mapGetters, mapMutations } from "vuex"
import _ from "lodash"

export default {
  name: "ReportPage",
  components: {
    ReportDownload,
    ReportForm,
    AuthLayout,
    StepsLayout,
    VuePdfEmbed,
  },
  data() {
    return {
      breadcrumbs: [
        { name: `Requests`, route: { name: `cms.requests` } },
        { name: this.$route.params.barcode },
      ],
    }
  },
  computed: {
    ...mapGetters({
      getPdf: 'CmsRequestStore/getPdf',
      requestInit: 'CmsRequestStore/getInit',
    }),
    barcode() {
      return this.$route.params.barcode
    },
    pdf() {
      return this.getPdf(this.barcode) || ''
    },
  },
  methods: {
    sum(v) { return _.sum(v) }, // lodash sum
    ...mapMutations({
      setPdf: 'CmsRequestStore/setPdf',
    }),
    async loadPdf() {
      await CmsReportService.getPdf(this.barcode)
        .then((response) => {
          if (response.status === 200) {
            this.setPdf({ barcode: this.barcode, value: response.data })
          }
        })
    },
  },
  async mounted() {
    await this.loadPdf()
  },
}
</script>

<style lang="scss" scoped>
.ReportPage {
  width: calc(100% - 320px - 24px); // scrollbar-width: thin fix
  background-color: #edf1f8;
  
  &:deep(.vue-pdf-embed) {
    height: 100%;
    overflow-y: scroll;
    scrollbar-width: thin;
    scrollbar-color: rgba(0, 0, 0, 0.15) transparent;
    
    &::-webkit-scrollbar {
        width: 13px;
    }
  
    &::-webkit-scrollbar-thumb {
        border-radius: 5px 15px 15px 5px;
        background-color: rgba(0, 0, 0, 0.15);
        border-right: 8px transparent solid;
        background-clip: padding-box;
    }
  
    &::-webkit-scrollbar-track {
        margin: 10px;
    }
    
    & > div {
      &:not(:last-of-type) {
        margin-bottom: 30px;
      }
    }
    
    canvas {
      width: calc(100% - 6px) !important;
      height: auto !important;
    }
  }
  
  &__pdf-skeleton {
    width: 100%;
    height: 100%;
    padding: 24px;
    
    &:deep(.ant-skeleton-image) {
      width: 100%;
      height: 100%;
    }
  }
}
</style>